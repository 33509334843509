var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "add-new-user-sidebar",
      visible: _vm.isSendStaffConnectionRequestSideBarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit(
          "update:is-send-staff-connection-request-side-bar-active",
          val
        )
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "div",
              [
                _c(
                  "b-card",
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
                      },
                      [
                        _c("h4", { staticClass: "mb-0" }, [
                          _vm._v("Share staff")
                        ]),
                        _c("feather-icon", {
                          staticClass: "ml-1 cursor-pointer",
                          attrs: { icon: "XIcon", size: "16" },
                          on: { click: _vm.closeApp }
                        })
                      ],
                      1
                    ),
                    _c("b-col", { staticClass: "mt-1 mb-4" }, [
                      _c("p", [
                        _vm._v(
                          " Select the staff you would like to share with the PCN or other organisation. You can stop sharing staff by deselecting them "
                        )
                      ])
                    ]),
                    _c(
                      "b-col",
                      {
                        staticClass: "pb-0 mt-1",
                        attrs: { cols: "12", md: "12" }
                      },
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "input-group-merge mb-1" },
                          [
                            _c(
                              "b-input-group-prepend",
                              { attrs: { "is-text": "" } },
                              [
                                _c("feather-icon", {
                                  attrs: { icon: "SearchIcon" }
                                })
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              attrs: { placeholder: "Search" },
                              model: {
                                value: _vm.filters["search"],
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "search", $$v)
                                },
                                expression: "filters['search']"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { staticClass: "mb-2 mt-4" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "mt-2 mb-2",
                            attrs: { id: "All", name: "All" },
                            nativeOn: {
                              change: function($event) {
                                return _vm.SelectOrUnselectAll($event)
                              }
                            }
                          },
                          [_c("span", [_c("b", [_vm._v("Select All Staff")])])]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-overlay",
                      { attrs: { show: _vm.staffLoading, rounded: "sm" } },
                      [
                        _c(
                          "ul",
                          { staticClass: "list-group" },
                          [
                            _vm._l(_vm.pcnStaff, function(role) {
                              return _c(
                                "li",
                                {
                                  key: role.id,
                                  staticClass: "todo-item list-group-item py-1"
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", { attrs: { md: "9" } }, [
                                        _c(
                                          "span",
                                          { staticClass: "fw-bolder" },
                                          [_vm._v(_vm._s(role.name))]
                                        )
                                      ]),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "mb-1",
                                              attrs: {
                                                value: role.name,
                                                id: role.name
                                              },
                                              nativeOn: {
                                                change: function($event) {
                                                  return _vm.checkGroup(
                                                    role,
                                                    $event
                                                  )
                                                }
                                              },
                                              model: {
                                                value: _vm.all,
                                                callback: function($$v) {
                                                  _vm.all = $$v
                                                },
                                                expression: "all"
                                              }
                                            },
                                            [
                                              role.name
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "todo-title font-weight-bolder"
                                                    },
                                                    [_vm._v("Select All")]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._l(role.array, function(person, index) {
                                    return _c(
                                      "span",
                                      {
                                        key: index,
                                        staticClass:
                                          "todo-item list-group-item py-1"
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            attrs: {
                                              value: person,
                                              id:
                                                role.name +
                                                "_" +
                                                person.user_id +
                                                "_" +
                                                person.pcn_id,
                                              "value-field": "item"
                                            },
                                            nativeOn: {
                                              change: function($event) {
                                                return _vm.getStaffs(
                                                  person,
                                                  role,
                                                  $event
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.checkedStaffMembers,
                                              callback: function($$v) {
                                                _vm.checkedStaffMembers = $$v
                                              },
                                              expression: "checkedStaffMembers"
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(person.user_name))
                                            ]),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  " - " + person.practice_name
                                                )
                                              )
                                            ]),
                                            person.is_locum == 1
                                              ? _c(
                                                  "b-badge",
                                                  {
                                                    staticClass: "mr-1 mb-1",
                                                    staticStyle: {
                                                      padding: "0.8rem"
                                                    },
                                                    attrs: {
                                                      variant: "light-info"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-dark font-weight-700"
                                                      },
                                                      [_vm._v("L")]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            }),
                            _c(
                              "b-col",
                              { staticClass: "mt-1", attrs: { "cols-12": "" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(255, 255, 255, 0.15)",
                                        expression:
                                          "'rgba(255, 255, 255, 0.15)'",
                                        modifiers: { "400": true }
                                      }
                                    ],
                                    staticClass: "mb-2",
                                    attrs: {
                                      type: "submit",
                                      variant: "danger"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.blockOrRestoreUser()
                                      }
                                    }
                                  },
                                  [_vm._v(" ARCHIVE ")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(186, 191, 199, 0.15)",
                                        expression:
                                          "'rgba(186, 191, 199, 0.15)'",
                                        modifiers: { "400": true }
                                      }
                                    ],
                                    staticClass: "mb-2 ml-1",
                                    attrs: {
                                      type: "reset",
                                      variant: "outline-secondary"
                                    },
                                    on: { click: hide }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-secondary" },
                                      [_vm._v(" Cancel ")]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }