var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("b-col", { attrs: { cols: "12", md: "5" } }),
      _c(
        "b-col",
        { staticClass: "mb-1", attrs: { cols: "12", md: "12" } },
        [
          _c(
            "b-tabs",
            { attrs: { lazy: "" } },
            [
              _c(
                "b-tab",
                {
                  attrs: {
                    lazy: "",
                    title: "Connected PCNs",
                    active: _vm.$route.meta.tab === "3"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "alert-body" },
                    [_c("ConnectedPCNs")],
                    1
                  )
                ]
              ),
              _c(
                "b-tab",
                {
                  attrs: { lazy: "", active: _vm.$route.meta.tab === "4" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("span", {}, [
                            _c("strong", [_vm._v("Connection Requests")]),
                            _vm.noOfPcnRequest > 0
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "ml-1 translate-middle badge rounded-circle bg-danger",
                                    staticStyle: { "font-size": "0.8em" }
                                  },
                                  [_vm._v(" " + _vm._s(_vm.noOfPcnRequest))]
                                )
                              : _vm._e()
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "div",
                    { staticClass: "alert-body" },
                    [
                      _c("ConnectionRequests", {
                        on: {
                          dataRefresh: function($event) {
                            return _vm.dataRefresh()
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "b-tab",
                {
                  attrs: { lazy: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("span", {}, [
                            _c("strong", [_vm._v("Requested Connections")]),
                            _c("span", {
                              staticClass:
                                "ml-1 translate-middle badge rounded-circle bg-danger",
                              staticStyle: { "font-size": "0.8em" }
                            })
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "div",
                    { staticClass: "alert-body" },
                    [_c("RequestedConnection")],
                    1
                  )
                ]
              ),
              _c("b-tab", { attrs: { lazy: "", title: "Services" } }, [
                _c("div", { staticClass: "alert-body" }, [_c("services")], 1)
              ]),
              _c("b-tab", { attrs: { lazy: "", title: "Documents" } }, [
                _c("div", { staticClass: "alert-body" }, [_c("documents")], 1)
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("AddLocationSideBar", {
        ref: "AddLocationSideBar",
        attrs: {
          "is-add-new-location-sidebar-active":
            _vm.isAddNewLocationSidebarActive
        },
        on: {
          "update:isAddNewLocationSidebarActive": function($event) {
            _vm.isAddNewLocationSidebarActive = $event
          },
          "update:is-add-new-location-sidebar-active": function($event) {
            _vm.isAddNewLocationSidebarActive = $event
          },
          toggleSideBars: function($event) {
            return _vm.toggleSideBars()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }