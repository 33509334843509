import { render, staticRenderFns } from "./addServiceSideBar.vue?vue&type=template&id=85f9b0e6&scoped=true&"
import script from "./addServiceSideBar.vue?vue&type=script&lang=js&"
export * from "./addServiceSideBar.vue?vue&type=script&lang=js&"
import style0 from "./addServiceSideBar.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./addServiceSideBar.vue?vue&type=style&index=1&id=85f9b0e6&lang=scss&scoped=true&"
import style2 from "./addServiceSideBar.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85f9b0e6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\10QBIT\\Hyre\\hyre_mp_fe\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('85f9b0e6')) {
      api.createRecord('85f9b0e6', component.options)
    } else {
      api.reload('85f9b0e6', component.options)
    }
    module.hot.accept("./addServiceSideBar.vue?vue&type=template&id=85f9b0e6&scoped=true&", function () {
      api.rerender('85f9b0e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/medicalpractice/sidebars/addServiceSideBar.vue"
export default component.exports