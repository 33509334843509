<template>
  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isAddServiceSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"

      @hidden="resetForm"
  >
    <template #default="{ hide }">
      <b-overlay
          :show="tableLoading"
          rounded="sm"
      >

        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4 class="mb-0">
            Add Service
          </h4>

          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
          />

        </div>
        <div class="p-2">
          <p>Select from the list of existing services</p>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="icons-search"
                      v-model="filters['name']"
                      placeholder="Search"

                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <v-select
                  v-model="filters['type']"
                  :options="[{
                    title:'All',
                    value:''
            },
            {
              title:'Clinical',
              value:'clinical'
            },
            {
              title:'Non-Clinical',
              value:'non-clinical'
            }]"
                  label="title"
                  placeholder="Select service type"

              />
            </b-col>
          </b-row>
          <!--          :select-mode="`multi`"-->
          <b-table
              id="services_table"
              ref="table"
              :current-page="currentPage"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              :items="getService"
              :per-page="pagination.perPage"
              :selectOnCheckboxOnly="true"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              class="custom_align mobile_table_css bank-tbl"
              hover
              responsive


          >
            <!--            @row-selected="onRowSelected"-->
            <template #cell(selected)="data">
              <b-form-checkbox class="p-0 m-0" name="check_box" @change="addThisToSelectedList(data.item)"></b-form-checkbox>
            </template>
            <!--            <template #cell(selected)="{ rowSelected }">



            &lt;!&ndash;              <template v-if="rowSelected">
                            <b-form-checkbox @change="addThisToSelectedList(rowSelected)" :checked="true"></b-form-checkbox>
                            &lt;!&ndash;              <span aria-hidden="true">&check;</span>&ndash;&gt;
                            &lt;!&ndash;              <span class="sr-only">Selected</span>&ndash;&gt;
                          </template>
                          <template v-else >
                            &lt;!&ndash;              <span aria-hidden="true">&nbsp;</span>&ndash;&gt;
                            &lt;!&ndash;              <span class="sr-only">Not selected</span>&ndash;&gt;
                            <b-form-checkbox></b-form-checkbox>
                          </template>&ndash;&gt;
                        </template>-->
            <!-- Name $ Avatar -->

            <!--          <template #cell(check)="data,index">-->
            <!--            <b-form-checkbox ></b-form-checkbox>-->
            <!--          </template>-->

            <template #cell(name)="data">
              <b-col>
                <b-media no-body>

                  {{ data.item.name }}

                </b-media>
              </b-col>
            </template>
            <template #cell(type)="data">
              <b-badge v-if="data.item.type ==='clinical'" :variant="'light-success'" class="text-capitalize">
                {{ data.item.type }}
              </b-badge>
              <b-badge v-else :variant="'light-info'" class="text-capitalize">
                {{ data.item.type }}
              </b-badge>
            </template>

            <!-- Paid Unpaid Button -->

          </b-table>


          <b-row>

            <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
              <span v-if="pagination.totalRows !==0"
                    class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{
                  pagination.totalRows
                }} entries</span>
            </b-col>

            <!-- Pagination -->

            <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

              <b-pagination
                  v-model="currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  class="mb-0 mt-1 mt-sm-0 "
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item">

                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>

                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>

              </b-pagination>

            </b-col>
          </b-row>
          <div class="d-flex mt-3">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="selected.length === 0 || hide_btn"
                type="submit"
                variant="primary"
                @click="updateService()"
            >
              Add
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="text-primary ml-1"
                type="button"
                variant="outline-white"
                @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BLink,
  BListGroup,
  BListGroupItem,
  BMedia,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  BTable,
  VBTooltip
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import settings from '@/apis/modules/settings'
import Filter from '@/mixins/FilterMixin'


export default {
  name: 'addServiceSideBar',

  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BRow,
    BCol,
    BInputGroupPrepend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BCard,

    BAvatar,
    BLink,
    BBadge,
    BCardBody,
    BCardHeader,
    BImg,
    BMedia,
    BTable,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    BOverlay,


    VBTooltip
  },
  mixins: [Filter],

  directives: {
    Ripple
  },
  model: {
    prop: 'isAddServiceSidebarActive',
    event: 'update:is-add-service-sidebar-active'
  },
  props: {
    isAddServiceSidebarActive: {
      type: Boolean,
      required: true
    },
    roleOptions: {
      type: Array,
      required: false
    },
    planOptions: {
      type: Array,
      required: false
    }
  },
  // watch: {
  //   currentPage () {
  //
  //     this.getService()
  //   }
  // },
  data () {
    return {
      hide_btn: true,
      tableLoading: false,
      filters: {},
      selected: [],
      selectedCheck: true,

      perfectScrollbarSettings: {
        maxScrollbarLength: 100
      },
      selectedOption: {
        title: 'All',
        value: 1
      },
      noDataTable: '',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      fields: [
        {
          key: 'selected',
          label: ''
        },
        {
          key: 'name',
          label: ' NAME'
        },
        {
          key: 'type',
          label: 'Type'
        }


      ],
      items: []
    }
  },
  methods: {
    addThisToSelectedList (item) {

      if (_.find(this.selected, {'id': item.id})) {

        _.remove(this.selected, function (e) {
          return e.id === item.id
        })
      } else {
        this.selected.push(item)
      }
      this.hide_btn = this.selected.length === 0
    },
    resetForm () {
      // this.$emit('toggleSideBars')
    },
    toggle () {
      // this.$emit('toggleSideBars')
      this.isAddServiceSidebarActive = false
      this.selected = []
      this.filters = {}
      this.$refs.table.refresh()
    },
    onRowSelected (items) {
      // this.selected = items
    },
    async getService () {
      try {
        this.tableLoading = true
        const Response = await settings.getServicesToAdd(this.currentPage, 15, this.filterQuery)
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
          type: x.type
        }))

        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false

        var checkboxes = document.getElementsByName('check_box')
        for (var checkbox of checkboxes) {
          checkbox.checked = false
        }

        return dataArray

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }

    },
    async updateService () {
      try {
        this.tableLoading = true


        const data = this.selected.map((x) => (
            x.id
        ))

        console.log(data)
        console.log(this.selected)
        await settings.updateServiceForPractice({service_ids: data})
        this.$emit('dataRefresh')
        // this.toggle()

        this.showSuccessMessage('Service Added To Practice ')
        this.$refs.table.refresh()
        this.tableLoading = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false

      }
    },
    filterQueryUpdate () {
      this.$refs.table.refresh()
    }
  },
  mounted () {

  },
  watch: {
    isAddServiceSidebarActive (val) {
      if (val) this.getService()
    },
    selected (val) {
    }
  }

}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }

  //.table thead th {
  //  border-bottom: 2px solid #ebe9f1;
  //  visibility: hidden !important;
  //}
}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }

}
</style>

<style lang="scss">
#services_table td:nth-child(2) {
  padding-left: 15px !important;
}
@import "~@core/scss/base/pages/app-todo.scss";
</style>

