<template>
  <div>
    <router-view></router-view>
    <b-card class="mb-0" no-body>
      <div>
        <b-row>
          <b-col cols="3">
            <b-form-group>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="filters['search']"
                  id="icons-search"
                  placeholder="Search"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="9">
            <b-button
              class="float-right mb-1 button-size"
              size="m"
              variant="primary"
              @click="toggleSideBars()"
            >
              <span class="align-middle">Send Connection Requests</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-overlay :show="tableLoading" rounded="sm">
          <b-row>
            <b-col>
              <b-table
                ref="table"
                :current-page="currentPage"
                :per-page="pagination.perPage"
                :fields="fields"
                :items="getConnectedPcn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                class="position-relative mobile_table_css"
                empty-text=""
                hover
                primary-key="id"
                responsive
                show-empty
              >
                <!-- Name & Badge -->
                <template #cell(pcn_name)="data">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      {{ data.item.pcn_name }}
                      <b-badge variant="light-primary" class="font-weight-bold">
                        <feather-icon icon="LinkIcon" />
                      </b-badge>
                    </li>
                  </ul>
                </template>

                <template v-slot:head(name)="data">
                  <span v-b-tooltip.hover.viewport="'Sort'">{{
                    data.label
                  }}</span>
                </template>

                <!-- Status -->

                <template #cell(status)="data">
                  <b-badge :variant="'light-success'" class="text-capitalize">
                    Connected
                  </b-badge>
                </template>
                <template #cell(setting)="data">
                  <b-row class="ml-2">
                    <feather-icon
                      icon="SettingsIcon"
                      size="18"
                      @click="toggleConnectStaffSideBars(data.item)"
                    />
                  </b-row>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-overlay>

        <!--                pagination-->
        <div class="mx-1 mb-2">
          <b-row>
            <b-col cols="12" v-if="noDataTable === 0" class="text-center">
              <span>No data for preview</span>
            </b-col>
            <b-col
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
              cols="12"
              sm="6"
            >
              <span class="text-muted" v-if="pagination.totalRows !== 0"
                >Showing {{ pagination.from }} to {{ pagination.to }} of
                {{ pagination.totalRows }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
              cols="12"
              sm="6"
            >
              <b-pagination
                v-model="currentPage"
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                class="mb-0 mt-1 mt-sm-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>

    <SendConnectionRequestSideBar
      ref="SendConnectionRequestSideBar"
      :is-send-connection-request-side-bar-active="isSendConnectionRequestSideBarActive"
      @toggleSideBars="toggleSideBars()"
      @dataRefresh="dataRefresh()"
    />

    <SendStaffConnectionRequestSideBar
      ref="SendStaffConnectionRequestSideBar"
      :is-send-staff-connection-request-side-bar-active="isSendStaffConnectionRequestSideBarActive"
      :pcn_id="pcn_id"
      @toggleConnectStaffSideBars="toggleConnectStaffSideBars()"
      @dataRefresh="dataRefresh()"
    />
  </div>
</template>

<script>
import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BOverlay,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import PCNApi from "@/apis/modules/pcn";
import SendConnectionRequestSideBar from "@/views/medicalpractice/sidebars/sendConnectionRequestSideBar.vue";
import SendStaffConnectionRequestSideBar from "@/views/medicalpractice/sidebars/sendStaffConnectionRequestSideBar.vue";
import Filter from "@/mixins/FilterMixin";
import MomentMixin from "@/mixins/MomentMixin";
import ErrorMessages from "@/mixins/ErrorMessages";
import SuccessMessage from "@/mixins/SuccessMessage";

export default {
  name: "map-list",
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BOverlay,
    vSelect,
    FeatherIcon,
    SendConnectionRequestSideBar,
    SendStaffConnectionRequestSideBar,
    VBTooltip,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],
  data() {
    return {
      currentPage: 1,
      tableLoading: false,
      filters: {},
      noDataTable: "",
      isSendConnectionRequestSideBarActive: false,
      isSendStaffConnectionRequestSideBarActive:false,
      pcn_id:'',
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      pagination: {
        perPage: "",
        pageOptions: "",
        totalRows: "",
        from: "",
        to: "",
      },
      fields: [
        {
          key: "name",
          label: "NAME",
          sortable: true,
        },
        {
          key: "address",
          label: "Address",
        },
        {
          key: "city",
          label: "CITY",
        },
        {
          key: "postal_code",
          label: "POSTAL CODE",
        },
        {
          key: "telephone",
          label: "TELEPHONE",
        },
        {
          key: "email",
          label: "EMAIL",
        },
        {
          key: "status",
          label: "STATUS",
          sortable: false,
        },
        {
          key: "setting",
          label: "",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    dataRefresh() {
      this.$refs.table.refresh();
      this.isSendConnectionRequestSideBarActive = false;
      this.isSendStaffConnectionRequestSideBarActive= false
    },
    toggleSideBars() {
      this.isSendConnectionRequestSideBarActive =
        !this.isSendConnectionRequestSideBarActive;
    },
    toggleConnectStaffSideBars(item){
      this.pcn_id = item.pcn_id
      this.isSendStaffConnectionRequestSideBarActive = !this.isSendStaffConnectionRequestSideBarActive
    },
    async getConnectedPcn() {
      try {
        this.tableLoading = true;

        if (this.sortBy === "") {
          this.sortDesc = true;
        }

        const Response = await PCNApi.connectedPcns(
          this.currentPage,
          15,
          this.filterQuery,
          this.sortBy,
          this.sortDesc
        );

        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          pcn_id: x.p_c_n_id,
          address: x.pcn.address,
          city: x.pcn.city,
          name: x.pcn.name,
          postal_code: x.pcn.postal_code,
          telephone: x.pcn.owner.phone_number
            ? x.pcn.owner.phone_number
            : "N/A",
          email: x.pcn.owner.email,
        }));
        this.noDataTable = Response.data.data.length;
        const paginationResponse = Response.data.meta;
        this.currentPage = paginationResponse.current_page;
        this.pagination.totalRows = paginationResponse.total;
        this.pagination.perPage = paginationResponse.per_page;
        this.pagination.from = paginationResponse.from;
        this.pagination.to = paginationResponse.to;
        this.tableLoading = false;

        return dataArray;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.tableLoading = false;
      }
    },
    filterQueryUpdate() {
      this.$refs.table.refresh();
    },
  },
  mounted() {
    //this.getConnectedPcn();
  },
};
</script>

<style scoped>
.button-size {
  height: 38px;
}
</style>
