<template>
  <div class="row">


    <!--         <b-col cols="12" md="12" class="mb-1">-->
    <!--             <div class="text-dark">-->
    <!--                 <h3>Connected PCNs</h3>-->
    <!--             </div>-->
    <!--         </b-col>-->

    <!-- Search Button -->

    <!--      <b-col cols="12" md="4" class="mb-1">-->
    <!--         <b-form-group>-->
    <!--        <b-input-group class="input-group-merge">-->
    <!--          <b-input-group-prepend is-text>-->
    <!--            <feather-icon icon="SearchIcon" />-->
    <!--          </b-input-group-prepend>-->
    <!--          <b-form-input-->
    <!--            id="icons-search"-->
    <!--            placeholder="Search"-->
    <!--          />-->
    <!--        </b-input-group>-->
    <!--      </b-form-group>-->
    <!--      </b-col>-->


    <!-- Send Connection Request Button -->

    <b-col cols="12" md="5"></b-col>

    <!--    <b-col cols="12" md="3" class="flex-float-right">-->
    <!--         <b-form-group class="mb-0 ml-auto mr-1">-->
    <!--               <b-button-->
    <!--                  class="btn-sm-block float-right"-->
    <!--                  variant="primary"-->
    <!--                  @click="isAddNewLocationSidebarActive = true"-->
    <!--                >-->
    <!--                  <span class="align-middle">Send Connection Request</span>-->
    <!--               </b-button>-->
    <!--               </b-form-group>-->
    <!--    </b-col>-->

    <b-col class="mb-1" cols="12" md="12">
      <b-tabs lazy>
        <b-tab lazy title="Connected PCNs" :active="$route.meta.tab === '3'">
          <div class="alert-body">
            <ConnectedPCNs/>
          </div>
        </b-tab>

        <!-- <b-tab title="Connection Requests" lazy>
              <div class="alert-body"> <ConnectionRequests />
              </div>
        </b-tab> -->
        <b-tab lazy :active="$route.meta.tab === '4'">
          <template #title>
                      <span class=""><strong>Connection Requests</strong>
                      <span v-if="noOfPcnRequest>0" class="ml-1 translate-middle badge rounded-circle bg-danger"
                            style="font-size: 0.8em;"> {{ noOfPcnRequest }}</span>

                      </span>
          </template>
          <div class="alert-body">
            <ConnectionRequests
                @dataRefresh="dataRefresh()"/>

          </div>
        </b-tab>
        <b-tab lazy>
          <template #title>
                      <span class=""><strong>Requested Connections</strong>
                      <span class="ml-1 translate-middle badge rounded-circle bg-danger"
                            style="font-size: 0.8em;"></span>
                      </span>
          </template>
          <div class="alert-body">
            <RequestedConnection/>
          </div>
        </b-tab>
        <b-tab lazy title="Services">
          <div class="alert-body">
            <services/>
          </div>
        </b-tab>
        <b-tab lazy title="Documents">
          <div class="alert-body">
            <documents/>
          </div>
        </b-tab>
        <!-- <b-tab >
          <template #title>
            <span class="" ><strong>No data </strong>
            <span class="translate-middle badge rounded-pill bg-danger"
                  style="font-size: 0.6em;">{{ noData }}</span>
            </span>
          </template>
            <ConnectedPCNs />
          </b-tab> -->
      </b-tabs>
    </b-col>

    <AddLocationSideBar
        ref="AddLocationSideBar"
        :is-add-new-location-sidebar-active.sync="isAddNewLocationSidebarActive"
        @toggleSideBars="toggleSideBars()"
    />
  </div>
</template>


<script>
import vSelect from 'vue-select'
import ConnectedPCNs from './table/connectedpcns'
import ConnectionRequests from './table/connectionrequests'
import RequestedConnection from './table/requestedconnection'
import Ripple from 'vue-ripple-directive'

import AddLocationSideBar from '@/views/medicalpractice/sidebars/sendConnectionRequestSideBar'

import {
  BInputGroup, BFormInput, BInputGroupPrepend, BRow, BButton, BCol, BTabs, BTab,
  BFormGroup, BListGroup, BListGroupItem
} from 'bootstrap-vue'
import Services from '@/views/medicalpractice/view/table/services'
import Documents from '@/views/medicalpractice/view/documents'
import PCNApi from '@/apis/modules/pcn'

export default {
  name: 'Index',
  components: {
    Documents,
    Services,
    AddLocationSideBar,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    vSelect,
    ConnectedPCNs,
    ConnectionRequests,
    RequestedConnection,
    BTabs,
    BTab


  },
  directives: {
    Ripple
  },

  data () {
    return {
      currentPage: 1,
      prop: ['test'],
      isAddNewLocationSidebarActive: false,
      noOfPcnRequest: 0,
      option1: [{title1: 'February, 2021'}, {title1: 'January, 2021'}, {title1: 'December, 2020'}, {title1: 'November, 2020'}, {title1: 'Octomber, 2020'}]

    }
  },
  methods: {
    toggleSideBars () {
      this.isAddNewLocationSidebarActive = !this.isAddNewLocationSidebarActive
    },

    async getSummary () {
      const practiceSummary = await PCNApi.practiceSummary()
      this.noOfPcnRequest = practiceSummary.data.data.connection_requests
    },
    dataRefresh () {
      this.getSummary()
      this.$emit('dataRefresh')
    }

  },
  async mounted () {
    await this.getSummary()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

