var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("b-card", [_c("div", [_c("DetailBar")], 1)]),
      _c(
        "div",
        [
          _c(
            "b-row",
            { staticClass: "mt-n1 ml-0 mr-0" },
            [
              _c("b-col", { staticClass: "pb-2 pt-2", attrs: { cols: "11" } }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center mr-3 mb-1" },
                    [
                      _c(
                        "b-avatar",
                        { attrs: { variant: "light-success", rounded: "" } },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "LinkIcon", size: "18" }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "ml-1" }, [
                        _c("h4", { staticClass: "mb-0 font-weight-bolder " }, [
                          _vm._v(" " + _vm._s(this.totalConnected) + " ")
                        ]),
                        _c("small", [_vm._v("Connected PCNs")])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center mr-3 mb-1" },
                    [
                      _c(
                        "b-avatar",
                        { attrs: { variant: "light-danger", rounded: "" } },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "RepeatIcon", size: "18" }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "ml-1" }, [
                        _c("h4", { staticClass: "mb-0 font-weight-bolder" }, [
                          _vm._v(" " + _vm._s(this.totalRequest) + " ")
                        ]),
                        _c("small", [_vm._v("Connection Requests")])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center mr-3 mb-1" },
                    [
                      _c(
                        "b-avatar",
                        { attrs: { variant: "light-primary", rounded: "" } },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "UsersIcon", size: "18" }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "ml-1" }, [
                        _c("h4", { staticClass: "mb-0 font-weight-bolder" }, [
                          _vm._v(" " + _vm._s(this.totalStaff) + " ")
                        ]),
                        _c("small", [_vm._v("Total Staff")])
                      ])
                    ],
                    1
                  )
                ])
              ]),
              _c("b-col", {
                staticClass: "pt-0 pb-2 pt-xl-2 pb-xl-0 ml-n1 ",
                attrs: { cols: "1" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("b-card", [
        _c(
          "div",
          [
            _c("TableBar", {
              on: {
                dataRefresh: function($event) {
                  return _vm.dataRefresh()
                }
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }