<template>
    <div class="home">
        <b-card>
        <div><DetailBar/></div>
        </b-card>
       <div>
        <b-row class="mt-n1 ml-0 mr-0">

                <!-- Left side Icons -->

            <b-col cols="11"  class="pb-2 pt-2">
                <div class="row">

                <!-- 01 Connected PCNs -->

                    <div class="d-flex align-items-center mr-3 mb-1">

                        <b-avatar variant="light-success" rounded>
                            <feather-icon icon="LinkIcon" size="18"/>
                        </b-avatar>

                    <div class="ml-1">
                        <h4 class="mb-0 font-weight-bolder "> {{this.totalConnected}} </h4>
                        <small>Connected PCNs</small>
                        </div>
                    </div>

                <!-- 02 Connection Requests -->

                    <div class="d-flex align-items-center mr-3 mb-1">

                        <b-avatar variant="light-danger" rounded>
                            <feather-icon icon="RepeatIcon" size="18" />
                        </b-avatar>

                    <div class="ml-1">
                        <h4 class="mb-0 font-weight-bolder"> {{this.totalRequest}} </h4>
                        <small>Connection Requests</small>
                    </div>
                    </div>

                    <!-- 03 Total Staff -->


                    <div class="d-flex align-items-center mr-3 mb-1">

                        <b-avatar variant="light-primary" rounded>
                            <feather-icon icon="UsersIcon" size="18" />
                        </b-avatar>

                    <div class="ml-1">
                        <h4 class="mb-0 font-weight-bolder"> {{this.totalStaff}} </h4>
                        <small>Total Staff</small>
                    </div>
                    </div>
                </div>
            </b-col>

            <!-- Right side Downloading button -->

            <b-col cols="1"  class="pt-0 pb-2 pt-xl-2 pb-xl-0 ml-n1 ">
<!--                <b-row >-->
<!--                    <div class="d-flex justify-content-start  mt-2 mt-md-0 pl-0 pl-md-0 ml-auto">-->

<!--                        <b-button-->
<!--                                size="23"-->
<!--                                variant="outline-white"-->
<!--                                class="btn-icon bg-white text-primary"-->
<!--                                v-b-tooltip.hover.top="'Download'"-->
<!--                        >-->

<!--                            <feather-icon-->
<!--                                size="18"-->
<!--                                icon="DownloadIcon"-->
<!--                                />-->
<!--                        </b-button>-->
<!--                    </div>-->
<!--                </b-row>-->
            </b-col>
        </b-row>
       </div>

        <b-card>
        <div><TableBar
            @dataRefresh= "dataRefresh()"/></div>
        </b-card>

    </div>

</template>

<script>
import DetailBar from './view/detailbar'
import TableBar from './view/table'

import PCNApi from '@/apis/modules/pcn'

import { BAvatar, BBadge, BButton, BCol, BLink, BRow, BCard, BTab, BTabs, VBTooltip} from 'bootstrap-vue'

export default {
    name:'Home',
    components:{
        TableBar,
        DetailBar,
        BCard,
        BTab,
        BTabs,
        BAvatar,
        BBadge,
        BButton,
        BCol,
        BLink,
        BRow,
    },

  directives: {
    'b-tooltip': VBTooltip
  },

  data() {
    return {
      totalRequest: 0,
      totalConnected: 0,
      totalStaff: 0,
    }
  },
  methods:{

    async getSummary(){

        const practiceSummary = await PCNApi.practiceSummary()

        this.totalConnected = practiceSummary.data.data.connected_pcns
        this.totalRequest = practiceSummary.data.data.connection_requests
        this.totalStaff = practiceSummary.data.data.total_staff

    },

    dataRefresh(){
      this.getSummary()
    }

  },
    async mounted(){
    await this.getSummary()
  }
}
</script>

<style >

</style>
