var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "add-new-user-sidebar",
      visible: _vm.isAddServiceSidebarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: { hidden: _vm.resetForm },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "b-overlay",
              { attrs: { show: _vm.tableLoading, rounded: "sm" } },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
                  },
                  [
                    _c("h4", { staticClass: "mb-0" }, [
                      _vm._v(" Add Service ")
                    ]),
                    _c("feather-icon", {
                      staticClass: "ml-1 cursor-pointer",
                      attrs: { icon: "XIcon", size: "16" },
                      on: { click: hide }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "p-2" },
                  [
                    _c("p", [
                      _vm._v("Select from the list of existing services")
                    ]),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-input-group",
                                  { staticClass: "input-group-merge" },
                                  [
                                    _c(
                                      "b-input-group-prepend",
                                      { attrs: { "is-text": "" } },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "SearchIcon" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "icons-search",
                                        placeholder: "Search"
                                      },
                                      model: {
                                        value: _vm.filters["name"],
                                        callback: function($$v) {
                                          _vm.$set(_vm.filters, "name", $$v)
                                        },
                                        expression: "filters['name']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-select", {
                              attrs: {
                                options: [
                                  {
                                    title: "All",
                                    value: ""
                                  },
                                  {
                                    title: "Clinical",
                                    value: "clinical"
                                  },
                                  {
                                    title: "Non-Clinical",
                                    value: "non-clinical"
                                  }
                                ],
                                label: "title",
                                placeholder: "Select service type"
                              },
                              model: {
                                value: _vm.filters["type"],
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "type", $$v)
                                },
                                expression: "filters['type']"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("b-table", {
                      ref: "table",
                      staticClass: "custom_align mobile_table_css bank-tbl",
                      attrs: {
                        id: "services_table",
                        "current-page": _vm.currentPage,
                        fields: _vm.fields,
                        filter: _vm.filter,
                        "filter-included-fields": _vm.filterOn,
                        items: _vm.getService,
                        "per-page": _vm.pagination.perPage,
                        selectOnCheckboxOnly: true,
                        "sort-by": _vm.sortBy,
                        "sort-desc": _vm.sortDesc,
                        "sort-direction": _vm.sortDirection,
                        hover: "",
                        responsive: ""
                      },
                      on: {
                        "update:sortBy": function($event) {
                          _vm.sortBy = $event
                        },
                        "update:sort-by": function($event) {
                          _vm.sortBy = $event
                        },
                        "update:sortDesc": function($event) {
                          _vm.sortDesc = $event
                        },
                        "update:sort-desc": function($event) {
                          _vm.sortDesc = $event
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(selected)",
                            fn: function(data) {
                              return [
                                _c("b-form-checkbox", {
                                  staticClass: "p-0 m-0",
                                  attrs: { name: "check_box" },
                                  on: {
                                    change: function($event) {
                                      return _vm.addThisToSelectedList(
                                        data.item
                                      )
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "cell(name)",
                            fn: function(data) {
                              return [
                                _c(
                                  "b-col",
                                  [
                                    _c(
                                      "b-media",
                                      { attrs: { "no-body": "" } },
                                      [
                                        _vm._v(
                                          " " + _vm._s(data.item.name) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "cell(type)",
                            fn: function(data) {
                              return [
                                data.item.type === "clinical"
                                  ? _c(
                                      "b-badge",
                                      {
                                        staticClass: "text-capitalize",
                                        attrs: { variant: "light-success" }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(data.item.type) + " "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "b-badge",
                                      {
                                        staticClass: "text-capitalize",
                                        attrs: { variant: "light-info" }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(data.item.type) + " "
                                        )
                                      ]
                                    )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center justify-content-sm-start",
                            attrs: { cols: "12", sm: "6" }
                          },
                          [
                            _vm.pagination.totalRows !== 0
                              ? _c("span", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    "Showing " +
                                      _vm._s(_vm.pagination.from) +
                                      " to " +
                                      _vm._s(_vm.pagination.to) +
                                      " of " +
                                      _vm._s(_vm.pagination.totalRows) +
                                      " entries"
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center justify-content-sm-end",
                            attrs: { cols: "12", sm: "6" }
                          },
                          [
                            _c("b-pagination", {
                              staticClass: "mb-0 mt-1 mt-sm-0 ",
                              attrs: {
                                "per-page": _vm.pagination.perPage,
                                "total-rows": _vm.pagination.totalRows,
                                "first-number": "",
                                "last-number": "",
                                "next-class": "next-item",
                                "prev-class": "prev-item"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "prev-text",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "ChevronLeftIcon",
                                            size: "18"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "next-text",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "ChevronRightIcon",
                                            size: "18"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.currentPage,
                                callback: function($$v) {
                                  _vm.currentPage = $$v
                                },
                                expression: "currentPage"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex mt-3" },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(255, 255, 255, 0.15)",
                                expression: "'rgba(255, 255, 255, 0.15)'",
                                modifiers: { "400": true }
                              }
                            ],
                            attrs: {
                              disabled:
                                _vm.selected.length === 0 || _vm.hide_btn,
                              type: "submit",
                              variant: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.updateService()
                              }
                            }
                          },
                          [_vm._v(" Add ")]
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(186, 191, 199, 0.15)",
                                expression: "'rgba(186, 191, 199, 0.15)'",
                                modifiers: { "400": true }
                              }
                            ],
                            staticClass: "text-primary ml-1",
                            attrs: { type: "button", variant: "outline-white" },
                            on: { click: hide }
                          },
                          [_vm._v(" Cancel ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }