<template>
  <div>
    <div class="d-flex flex-row ">

                  <!-- Hospital Icon  -->
        <div class="mr-1">
          <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
             <rect width="56" height="56" rx="5" fill="#CCE6F4"/>
                  <g clip-path="url(#clip0)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.0557 15.3461H30.0227V14.3134C30.0227 14.0873 29.8384 13.9023 29.6118 13.9023H28.863C28.6365 13.9023 28.4521 14.0873 28.4521 14.3134V15.3461H27.4192C27.1926 15.3461 27.0083 15.5311 27.0083 15.7572V16.5059C27.0083 16.7322 27.1926 16.917 27.4192 16.917H28.4521V17.95C28.4521 18.176 28.6365 18.361 28.863 18.361H29.6118C29.8384 18.361 30.0227 18.176 30.0227 17.95V16.917H31.0557C31.2822 16.917 31.4678 16.7322 31.4678 16.5059V15.7572C31.4678 15.5311 31.2822 15.3461 31.0557 15.3461Z" fill="#1B9AAA"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9912 35.1985H34.3377V34.1514H22.9912V35.1985ZM33.0513 43.0416H34.3531V36.1408H33.0513V43.0416ZM29.1358 43.0416H32.1092V36.1408H29.1358V43.0416ZM25.2514 43.0416H28.1937V36.1408H25.2514V43.0416ZM23.0072 43.0416H24.3088V36.1408H23.0072V43.0416ZM39.3144 43.0416H38.0651V40.3697C38.0651 40.1095 37.8539 39.8985 37.594 39.8985C37.3342 39.8985 37.123 40.1095 37.123 40.3697V43.0416H35.2952V35.8162C35.2952 35.7733 35.2875 35.7326 35.2772 35.6933C35.2772 35.6853 35.2798 35.6777 35.2798 35.6697V33.6803C35.2798 33.4201 35.0686 33.209 34.8088 33.209H22.52C22.2598 33.209 22.0488 33.4201 22.0488 33.6803V35.6697C22.0488 35.721 22.0591 35.7692 22.0742 35.8155C22.068 35.846 22.0646 35.8777 22.0646 35.91V43.4262C22.0646 43.6866 22.2756 43.8974 22.5359 43.8974C22.5382 43.8974 22.5403 43.8969 22.5428 43.8967C22.6196 43.9513 22.7131 43.984 22.8147 43.984H39.3144C39.5742 43.984 39.7854 43.7731 39.7854 43.5129C39.7854 43.2525 39.5742 43.0416 39.3144 43.0416Z" fill="#171822"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7768 20.9759H38.568V19.8922H18.7768V20.9759ZM23.1431 18.9204H34.2007V13.0147H23.1431V18.9204ZM20.7411 43.0407H20.2218V22.0241H37.1229V36.9762C37.1229 37.2364 37.3341 37.4474 37.5939 37.4474C37.8538 37.4474 38.065 37.2364 38.065 36.9762V21.9184H39.0391C39.2989 21.9184 39.5101 21.7075 39.5101 21.4471V19.421C39.5101 19.1606 39.2989 18.9497 39.0391 18.9497H35.144V12.5434C35.144 12.2832 34.9328 12.0723 34.6717 12.0723H22.6719C22.4117 12.0723 22.2008 12.2832 22.2008 12.5434V18.9497H18.3055C18.0453 18.9497 17.8343 19.1606 17.8343 19.421V21.4471C17.8343 21.7075 18.0453 21.9184 18.3055 21.9184H19.2793V43.0407H17.5368C17.2766 43.0407 17.0657 43.2517 17.0657 43.512C17.0657 43.7722 17.2766 43.9832 17.5368 43.9832H20.7411C21.0014 43.9832 21.2122 43.7722 21.2122 43.512C21.2122 43.2517 21.0014 43.0407 20.7411 43.0407Z" fill="#171822"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M30.5244 16.4417C30.5244 16.4596 30.5091 16.4757 30.4911 16.4757H29.4582C29.2495 16.4757 29.0806 16.6446 29.0806 16.8527V17.8855C29.0806 17.9033 29.0639 17.9194 29.0473 17.9194H28.2972C28.2793 17.9194 28.2639 17.9033 28.2639 17.8855V16.8527C28.2639 16.6446 28.095 16.4757 27.8863 16.4757H26.8534C26.8355 16.4757 26.8201 16.4596 26.8201 16.4417V15.693C26.8201 15.6751 26.8355 15.6587 26.8534 15.6587H27.8863C28.095 15.6587 28.2639 15.49 28.2639 15.2819V14.2488C28.2639 14.2314 28.2806 14.2149 28.2972 14.2149H29.0473C29.0639 14.2149 29.0806 14.2314 29.0806 14.2488V15.2819C29.0806 15.49 29.2495 15.6587 29.4582 15.6587H30.4911C30.5091 15.6587 30.5244 15.6751 30.5244 15.693V16.4417ZM30.4911 14.9048H29.8345V14.2488C29.8345 13.8143 29.4812 13.4609 29.0473 13.4609H28.2972C27.8633 13.4609 27.51 13.8143 27.51 14.2488V14.9048H26.8534C26.4195 14.9048 26.0657 15.2584 26.0657 15.693V16.4417C26.0657 16.8762 26.4195 17.2298 26.8534 17.2298H27.51V17.8855C27.51 18.3201 27.8633 18.6734 28.2972 18.6734H29.0473C29.4812 18.6734 29.8345 18.3201 29.8345 17.8855V17.2298H30.4911C30.9251 17.2298 31.2783 16.8762 31.2783 16.4417V15.693C31.2783 15.2584 30.9251 14.9048 30.4911 14.9048Z" fill="#171822"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.2737 27.5692C23.0134 27.5692 22.8025 27.7802 22.8025 28.0404V28.7315C22.8025 28.9917 23.0134 29.2026 23.2737 29.2026H26.7293C26.9891 29.2026 27.2003 28.9917 27.2003 28.7315V25.2759C27.2003 25.0156 26.9891 24.8047 26.7293 24.8047H23.2737C23.0134 24.8047 22.8025 25.0156 22.8025 25.2759V25.9672C22.8025 26.2274 23.0134 26.4384 23.2737 26.4384C23.534 26.4384 23.745 26.2274 23.745 25.9672V25.747H26.2582V28.2602H23.745V28.0404C23.745 27.7802 23.534 27.5692 23.2737 27.5692Z" fill="#171822"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1581 28.2602H33.6721V25.747H31.1581V28.2602ZM30.6871 24.8047C30.4273 24.8047 30.2161 25.0156 30.2161 25.2759V28.7315C30.2161 28.9917 30.4273 29.2026 30.6871 29.2026H34.1431C34.403 29.2026 34.6141 28.9917 34.6141 28.7315V25.2759C34.6141 25.0156 34.403 24.8047 34.1431 24.8047H30.6871Z" fill="#171822"/>
                    </g>
               <defs>
                 <clipPath id="clip0">
                   <rect width="22.912" height="32" fill="white" transform="translate(17 12)"/>
                   </clipPath>
                </defs>
           </svg>

        </div>

      <div>
        <h2 class="font-weight-bold">{{practice.name}}</h2>
        <span class="mr-1">
                    <feather-icon icon="MapPinIcon"/>
                    {{practice.address}}, {{practice.city}}, {{practice.postal_code}}
        </span>
        <span class="mr-1">
                    <feather-icon icon="PhoneIcon"/>
                  {{practice.phone_number}}
        </span>
        <span class="mr-1">
                    <feather-icon icon="MailIcon"/>
                   {{practice.email}}
        </span>
<!--        <span class="mr-1">-->
<!--                    <feather-icon icon="CreditCardIcon"/>-->
<!--                   £55/hour-->
<!--        </span>-->

      </div>
      <div class="ml-auto">
<!--        <b-button-->
<!--            class="btn-icon bg-white shadow-sm font-weight-bold mr-1 text-primary"-->
<!--            variant="white"-->
<!--        @click="$router.push('/medicalpractice/view/document')">-->
<!--          <feather-icon icon="FileIcon" size="16" class="mr-1"/>-->
<!--          <span class="text-primary">View Documents</span>-->
<!--        </b-button>-->
        <b-button
            class="btn-icon bg-white shadow-sm font-weight-bold text-primary"
            variant="white"
            @click="isAddNewLocationSidebarActive = true"
             v-b-tooltip.hover.top="'Edit'"
            >
          <feather-icon icon="EditIcon" size="20"/>
        </b-button>
      </div>
    </div>
<AddLocationSideBar v-if="practice && practice.id"
        ref="AddLocationSideBar"
        :is-add-new-location-sidebar-active.sync="isAddNewLocationSidebarActive"
        :practice="practice"
        @toggleSideBars="toggleSideBars()"
        @resetData= "resetData()"
    />

  </div>
</template>

<script>

import AddLocationSideBar from '@/views/medicalpractice/sidebars/editOrganisationDetailsSideBar.vue'
import practice from '@/apis/modules/practice'


import {

  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'program-list',
  components: {
    AddLocationSideBar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BTabs,
    BTab,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      isAddNewLocationSidebarActive: false,
      practice: {
        practice : '',
        address : '',
        phone_number : '',
        email : '',
      },
    }
  },
  methods: {
    resetData(){
      this.getPracticeDetails()
    },
    toggleSideBars() {
      this.isAddNewLocationSidebarActive = !this.isAddNewLocationSidebarActive
    },
    async getPracticeDetails(){
      try {
        const practiceResponse = await practice.getPracticeDetails()
        this.practice = practiceResponse.data.data

      }catch (error){
        this.convertAndNotifyError(error)
      }

    },
  },
  mounted () {
    this.getPracticeDetails()

  }
}
</script>

<style scoped>

</style>
