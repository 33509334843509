var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("router-view"),
      _c("b-card", { staticClass: "mb-0", attrs: { "no-body": "" } }, [
        _c(
          "div",
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "3" } },
                  [
                    _c(
                      "b-form-group",
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "input-group-merge" },
                          [
                            _c(
                              "b-input-group-prepend",
                              { attrs: { "is-text": "" } },
                              [
                                _c("feather-icon", {
                                  attrs: { icon: "SearchIcon" }
                                })
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              attrs: {
                                id: "icons-search",
                                placeholder: "Search"
                              },
                              model: {
                                value: _vm.filters["search"],
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "search", $$v)
                                },
                                expression: "filters['search']"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "9" } },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "float-right mb-1 button-size",
                        attrs: { size: "m", variant: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.toggleSideBars()
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "align-middle" }, [
                          _vm._v("Send Connection Requests")
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "b-overlay",
              { attrs: { show: _vm.tableLoading, rounded: "sm" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("b-table", {
                          ref: "table",
                          staticClass: "position-relative mobile_table_css",
                          attrs: {
                            "current-page": _vm.currentPage,
                            "per-page": _vm.pagination.perPage,
                            fields: _vm.fields,
                            items: _vm.getConnectedPcn,
                            "sort-by": _vm.sortBy,
                            "sort-desc": _vm.sortDesc,
                            "sort-direction": _vm.sortDirection,
                            "empty-text": "",
                            hover: "",
                            "primary-key": "id",
                            responsive: "",
                            "show-empty": ""
                          },
                          on: {
                            "update:sortBy": function($event) {
                              _vm.sortBy = $event
                            },
                            "update:sort-by": function($event) {
                              _vm.sortBy = $event
                            },
                            "update:sortDesc": function($event) {
                              _vm.sortDesc = $event
                            },
                            "update:sort-desc": function($event) {
                              _vm.sortDesc = $event
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "cell(pcn_name)",
                              fn: function(data) {
                                return [
                                  _c("ul", { staticClass: "list-inline" }, [
                                    _c(
                                      "li",
                                      { staticClass: "list-inline-item" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(data.item.pcn_name) + " "
                                        ),
                                        _c(
                                          "b-badge",
                                          {
                                            staticClass: "font-weight-bold",
                                            attrs: { variant: "light-primary" }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: { icon: "LinkIcon" }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "head(name)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.viewport",
                                          value: "Sort",
                                          expression: "'Sort'",
                                          modifiers: {
                                            hover: true,
                                            viewport: true
                                          }
                                        }
                                      ]
                                    },
                                    [_vm._v(_vm._s(data.label))]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(status)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "b-badge",
                                    {
                                      staticClass: "text-capitalize",
                                      attrs: { variant: "light-success" }
                                    },
                                    [_vm._v(" Connected ")]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(setting)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-2" },
                                    [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "SettingsIcon",
                                          size: "18"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.toggleConnectStaffSideBars(
                                              data.item
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mx-1 mb-2" },
              [
                _c(
                  "b-row",
                  [
                    _vm.noDataTable === 0
                      ? _c(
                          "b-col",
                          { staticClass: "text-center", attrs: { cols: "12" } },
                          [_c("span", [_vm._v("No data for preview")])]
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "\n              d-flex\n              align-items-center\n              justify-content-center justify-content-sm-start\n            ",
                        attrs: { cols: "12", sm: "6" }
                      },
                      [
                        _vm.pagination.totalRows !== 0
                          ? _c("span", { staticClass: "text-muted" }, [
                              _vm._v(
                                "Showing " +
                                  _vm._s(_vm.pagination.from) +
                                  " to " +
                                  _vm._s(_vm.pagination.to) +
                                  " of " +
                                  _vm._s(_vm.pagination.totalRows) +
                                  " entries"
                              )
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "\n              d-flex\n              align-items-center\n              justify-content-center justify-content-sm-end\n            ",
                        attrs: { cols: "12", sm: "6" }
                      },
                      [
                        _c("b-pagination", {
                          staticClass: "mb-0 mt-1 mt-sm-0",
                          attrs: {
                            "per-page": _vm.pagination.perPage,
                            "total-rows": _vm.pagination.totalRows,
                            "first-number": "",
                            "last-number": "",
                            "next-class": "next-item",
                            "prev-class": "prev-item"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "prev-text",
                              fn: function() {
                                return [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "ChevronLeftIcon",
                                      size: "18"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "next-text",
                              fn: function() {
                                return [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "ChevronRightIcon",
                                      size: "18"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.currentPage,
                            callback: function($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("SendConnectionRequestSideBar", {
        ref: "SendConnectionRequestSideBar",
        attrs: {
          "is-send-connection-request-side-bar-active":
            _vm.isSendConnectionRequestSideBarActive
        },
        on: {
          toggleSideBars: function($event) {
            return _vm.toggleSideBars()
          },
          dataRefresh: function($event) {
            return _vm.dataRefresh()
          }
        }
      }),
      _c("SendStaffConnectionRequestSideBar", {
        ref: "SendStaffConnectionRequestSideBar",
        attrs: {
          "is-send-staff-connection-request-side-bar-active":
            _vm.isSendStaffConnectionRequestSideBarActive,
          pcn_id: _vm.pcn_id
        },
        on: {
          toggleConnectStaffSideBars: function($event) {
            return _vm.toggleConnectStaffSideBars()
          },
          dataRefresh: function($event) {
            return _vm.dataRefresh()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }