var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "add-new-user-sidebar",
      visible: _vm.isSendConnectionRequestSideBarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit(
          "update:is-send-connection-request-side-bar-active",
          val
        )
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
              },
              [
                _c("h4", { staticClass: "mb-0" }, [
                  _vm._v(" Send Connection Requests ")
                ]),
                _c("feather-icon", {
                  staticClass: "ml-1 cursor-pointer",
                  attrs: { icon: "XIcon", size: "16" },
                  on: { click: hide }
                })
              ],
              1
            ),
            _c("validation-observer", { ref: "refFormObserver" }, [
              _c(
                "div",
                { staticClass: "p-2" },
                [
                  _c(
                    "label",
                    { staticClass: "font-small-3 font-weight-bold pb-1" },
                    [_vm._v("Select from the list of non connected PCN's")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pb-0", attrs: { cols: "12", md: "12" } },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "input-group-merge mb-1" },
                        [
                          _c(
                            "b-input-group-prepend",
                            { attrs: { "is-text": "" } },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "SearchIcon" }
                              })
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            attrs: { placeholder: "Search" },
                            model: {
                              value: _vm.filters["search"],
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "search", $$v)
                              },
                              expression: "filters['search']"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-overlay",
                    { attrs: { show: _vm.tableLoading, rounded: "sm" } },
                    [
                      _c("b-table", {
                        ref: "table",
                        staticClass: "custom_align mobile_table_css bank-tbl",
                        attrs: {
                          id: "connection_table",
                          "current-page": _vm.currentPage,
                          "per-page": _vm.pagination.perPage,
                          items: _vm.getNonConnectedPcns,
                          selectOnCheckboxOnly: true,
                          filter: _vm.filter,
                          "filter-included-fields": _vm.filterOn,
                          fields: _vm.fields,
                          hover: "",
                          responsive: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(selected)",
                              fn: function(data) {
                                return [
                                  _c("b-form-checkbox", {
                                    staticClass: "p-0 m-0",
                                    attrs: { name: "check_box" },
                                    on: {
                                      change: function($event) {
                                        return _vm.addThisToSelectedList(
                                          data.item
                                        )
                                      }
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _vm.noDataTable === 0
                        ? _c(
                            "b-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" }
                            },
                            [_c("span", [_vm._v("No data for preview")])]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mx-1 mb-2" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center justify-content-sm-start",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _vm.pagination.totalRows !== 0
                                ? _c("span", { staticClass: "text-muted" }, [
                                    _vm._v(
                                      "Showing " +
                                        _vm._s(_vm.pagination.from) +
                                        " to " +
                                        _vm._s(_vm.pagination.to) +
                                        " of " +
                                        _vm._s(_vm.pagination.totalRows) +
                                        " entries"
                                    )
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center justify-content-sm-end",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _c("b-pagination", {
                                staticClass: "mb-0 mt-1 mt-sm-0 ",
                                attrs: {
                                  "per-page": _vm.pagination.perPage,
                                  "total-rows": _vm.pagination.totalRows,
                                  "first-number": "",
                                  "last-number": "",
                                  "next-class": "next-item",
                                  "prev-class": "prev-item"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prev-text",
                                      fn: function() {
                                        return [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "ChevronLeftIcon",
                                              size: "18"
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "next-text",
                                      fn: function() {
                                        return [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "ChevronRightIcon",
                                              size: "18"
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.currentPage,
                                  callback: function($$v) {
                                    _vm.currentPage = $$v
                                  },
                                  expression: "currentPage"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex ml-1 mt-3" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(255, 255, 255, 0.15)",
                              expression: "'rgba(255, 255, 255, 0.15)'",
                              modifiers: { "400": true }
                            }
                          ],
                          attrs: {
                            disabled: _vm.selected.length === 0 || _vm.hide_btn,
                            type: "submit",
                            variant: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.updateRequest()
                            }
                          }
                        },
                        [_vm._v(" Send ")]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(186, 191, 199, 0.15)",
                              expression: "'rgba(186, 191, 199, 0.15)'",
                              modifiers: { "400": true }
                            }
                          ],
                          staticClass: "text-primary ml-1",
                          attrs: { type: "button", variant: "outline-white" },
                          on: { click: hide }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }