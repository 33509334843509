var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "add-new-user-sidebar",
      visible: _vm.isAddNewLocationSidebarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-add-new-location-sidebar-active", val)
      },
      hidden: function($event) {
        _vm.hide = false
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
              },
              [
                _c("h4", { staticClass: "mb-0" }, [
                  _vm._v(" Edit Organisation Details ")
                ]),
                _c("feather-icon", {
                  staticClass: "ml-1 cursor-pointer",
                  attrs: { icon: "XIcon", size: "16" },
                  on: { click: hide }
                })
              ],
              1
            ),
            _c("validation-observer", {
              ref: "refFormObserver",
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var handleSubmit = ref.handleSubmit
                      return [
                        _c(
                          "b-form",
                          {
                            staticClass: "p-2",
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return handleSubmit(_vm.onSubmit)
                              },
                              reset: function($event) {
                                $event.preventDefault()
                                return _vm.resetForm($event)
                              }
                            }
                          },
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Organisation name",
                                      "label-for": "h-name"
                                    }
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Organisation Name",
                                        rules: "required",
                                        vid: "h-name"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-input", {
                                                  staticClass: "mb-1",
                                                  attrs: {
                                                    id: "h-name",
                                                    placeholder:
                                                      "Organisation name"
                                                  },
                                                  model: {
                                                    value: _vm.form.name,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.name"
                                                  }
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Organisation type",
                                      "label-for": "h-type"
                                    }
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        label: "name",
                                        placeholder: "Select Organisation type",
                                        options: _vm.practiceList
                                      },
                                      model: {
                                        value: _vm.practice_type_id,
                                        callback: function($$v) {
                                          _vm.practice_type_id = $$v
                                        },
                                        expression: "practice_type_id"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Address 1",
                                      "label-for": "h-address-name"
                                    }
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Address",
                                        rules: "required",
                                        vid: "address"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "b-input-group",
                                                  {
                                                    staticClass:
                                                      "input-group-merge"
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group-prepend",
                                                      {
                                                        attrs: { "is-text": "" }
                                                      },
                                                      [
                                                        _c("feather-icon", {
                                                          attrs: {
                                                            icon: "MapPinIcon"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        id: "icons-address",
                                                        placeholder: "Address"
                                                      },
                                                      model: {
                                                        value: _vm.form.address,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "address",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.address"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Address 2",
                                      "label-for": "h-address-name"
                                    }
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Address",
                                        rules: "required",
                                        vid: "address"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "b-input-group",
                                                  {
                                                    staticClass:
                                                      "input-group-merge"
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group-prepend",
                                                      {
                                                        attrs: { "is-text": "" }
                                                      },
                                                      [
                                                        _c("feather-icon", {
                                                          attrs: {
                                                            icon: "MapPinIcon"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        id: "icons-address",
                                                        placeholder: "Address"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.address_2,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "address_2",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.address_2"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12", md: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            staticClass: "mr-1",
                                            attrs: {
                                              label: "City",
                                              "label-for": "h-city-name"
                                            }
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "City",
                                                rules: "required",
                                                vid: "City"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            id: "city",
                                                            placeholder:
                                                              "Address"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form.city,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "city",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.city"
                                                          }
                                                        }),
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "text-danger"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12", md: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Postal Code",
                                              "label-for": "h-postalcode-name"
                                            }
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Postal Code",
                                                rules: "required",
                                                vid: "Postal Code"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c("b-form-input", {
                                                          staticClass: "mb-1",
                                                          attrs: {
                                                            id:
                                                              "h-postalcode-name",
                                                            placeholder:
                                                              "Postal Code"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .postal_code,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "postal_code",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.postal_code"
                                                          }
                                                        }),
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "text-danger"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Telephone",
                                      "label-for": "h-telephone"
                                    }
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Telephone",
                                        rules: "required|tel|min_tel|max_tel",
                                        vid: "Telephone"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "b-input-group",
                                                  {
                                                    staticClass:
                                                      "input-group-merge"
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group-prepend",
                                                      {
                                                        attrs: { "is-text": "" }
                                                      },
                                                      [
                                                        _c("feather-icon", {
                                                          attrs: {
                                                            icon: "PhoneIcon"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        id: "icons-phone",
                                                        placeholder:
                                                          "0123456789"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.phone_number,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "phone_number",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.phone_number"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Email",
                                      "label-for": "h-email"
                                    }
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Email",
                                        rules: "email",
                                        vid: "Email"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "b-input-group",
                                                  {
                                                    staticClass:
                                                      "input-group-merge"
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group-prepend",
                                                      {
                                                        attrs: { "is-text": "" }
                                                      },
                                                      [
                                                        _c("feather-icon", {
                                                          attrs: {
                                                            icon: "MailIcon"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        id: "icons-mail",
                                                        placeholder:
                                                          "example@.com"
                                                      },
                                                      model: {
                                                        value: _vm.form.email,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "email",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "form.email"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Service charge per hour",
                                      "label-for": "h-charge"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      { staticClass: "input-group-merge" },
                                      [
                                        _c(
                                          "b-input-group-prepend",
                                          { attrs: { "is-text": "" } },
                                          [
                                            _c("feather-icon", {
                                              attrs: { icon: "DollarSignIcon" }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "icons-charge",
                                            placeholder: "0.00"
                                          },
                                          model: {
                                            value: _vm.form.service_charge,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "service_charge",
                                                $$v
                                              )
                                            },
                                            expression: "form.service_charge"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex mt-3 ml-1" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(255, 255, 255, 0.15)",
                                        expression:
                                          "'rgba(255, 255, 255, 0.15)'",
                                        modifiers: { "400": true }
                                      }
                                    ],
                                    attrs: {
                                      type: "submit",
                                      variant: "primary"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.updatePractice()
                                      }
                                    }
                                  },
                                  [_vm._v(" Save ")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(186, 191, 199, 0.15)",
                                        expression:
                                          "'rgba(186, 191, 199, 0.15)'",
                                        modifiers: { "400": true }
                                      }
                                    ],
                                    staticClass: "text-primary ml-1 shadow",
                                    attrs: {
                                      type: "button",
                                      variant: "outline-white"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.close()
                                      }
                                    }
                                  },
                                  [_vm._v(" Close ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }